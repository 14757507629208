<template>
    <div class="wrap">
        <div class="space_pc">
            <header class="header space">
                <div class="header_box">
                    <div class="logo">
                        <span>
                            <img src="/media/img/logo.svg" alt="" />
                            <!-- <img src="/media/img/logo_beta.svg" alt=""> -->
                        </span>
                    </div>
                </div>
            </header>
            <section class="main check">
                <div class="check_box">
                    <div class="check_img">
                        <div class="check_ball">
                            <div><img src="/media/img/icon_caution02.png" alt="" /></div>
                        </div>
                    </div>
                    <h1>서비스 점검중입니다</h1>
                    <p>
                        불편을 끼쳐드려서 죄송합니다.<br />
                        시스템 안정화를 위해 시스템 점검 중입니다.<br />
                        빠른 시간 내에 안정적인 서비스를 제공하겠습니다.<br />
                    </p>
                </div>
            </section>
        </div>
        <div class="space_mo h_100">
            <header class="header" style="background: transparent">
                <div class="header_box">
                    <div class="logo">
                        <span>
                            <img src="/media/img/logo_m.png" />
                        </span>
                    </div>
                </div>
            </header>
            <section class="main check">
                <div class="check_box">
                    <div class="check_img">
                        <div class="check_ball">
                            <div><img src="/media/img/icon_caution02.svg" alt="" /></div>
                        </div>
                    </div>
                    <h1>점검중입니다</h1>
                    <p>
                        불편을 끼쳐드려서 죄송합니다.<br />
                        시스템 안정화를 위해 시스템 점검 중입니다.<br />
                        빠른 시간 내에 안정적인 서비스를 제공하겠습니다.<br />
                    </p>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Inspect',
  created () {
    if (this.$store.getters.isLogin) {
      this.$router.push({ name: 'PlayerView' }).catch(() => {});
    } else {
      this.$router.push({ name: 'Login' }).catch(() => {});
    }
  },
  data () {
    return {};
  }
};
</script>

<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
